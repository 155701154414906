import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Layout from "src/components/layout"
import Breadcrumbs from "src/components/Breadcrumbs"
import BasicContent from "src/components/BasicContent"
import Image from "src/components/image"
import Accordion from "src/components/Accordion"
import SubpageBanner from "src/components/SubpageBanner"


const breadcrumbs = [
  {url: "womens-health-impact", handle: "Women's Health Impact"},
  {url: "womens-health-impact/clinical-results", handle: "Clinical Results"},
]

const ClinicalResults = () => (
  <StaticQuery
    query={graphql`
      query {
        background: file(relativePath: { eq: "discarded-cells-banner.jpg" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => {
      const background = data.background.childImageSharp.fluid
      return (
        <Layout>
          <SubpageBanner image={background} heading="Clinical Results" />
          <div className="wrapper wrapper--narrow p-bottom p-sides">
            <Breadcrumbs items={breadcrumbs} />
            <BasicContent>
              <h2>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eismud tempor incididun ut labore et.
              </h2>
              <p>
                While Hologic's ThinPrep Pap Test continue to be the industry's leading Liquid-Based Pap Test System,
                there is the potential for this system to perform even better. With aid from the exCellerator, clinicians can now capture a more complete
                sample for processing and testing. The lab can only test what it receives. With the exCellerator, the lab can reveive a more complete
                sample to work with, which can improve advocacy and chance of abnormal detection.
              </p>
            </BasicContent>
            <Accordion title="What if a significant portion of the sample collected by the clinician is discarded rather than transferred into the vial for testing?">
              <p>
                There are three main phases in the system prior to testing:
                Collection, Transfer, and Processing. Once the clinician
                ‘collects’ a quality cervical sample, the next critical step is
                ‘transferring’ the sample into the ThinPrep vial. Transferring
                the collected sample from the cytobrush is challenging and
                highly operator dependent. Given the nature of the sample, the
                cytobrush design, and its interaction with the fluid, simply
                ‘swishing’ without aid of the exCellerator does not reliably and
                consistently release the sample. This can result in a
                significant portion of the sample being discarded.
              </p>
            </Accordion>
            <Accordion title="What if cells carrying critical information for proper diagnosis, follow-up, and treatment were lost with the discarded sample rather than tested by the laboratory?">
                <p>
                  The sample collected with the cytobrush is important as it provides effective sampling of the endocervix and the transformation zone. Missing a portion of this sample can affect overall specimen adequacy, as well as the diagnostic accuracy of cytologic and molecular testing performed.
                </p>
                <p>
                  The lab cannot test a sample it does not receive. Clinicians work hard to collect a quality sample, and ensuring that the full sample makes it into the vial is essential.
                </p>
                <p>
                  The importance of a more complete sample cannot be overstated. If cells are discarded, critical information can be lost with potential impact such as:
                </p>
                <ul>
                  <li>Unnecessary unsatisfactory results</li>
                  <li>Missed or under-diagnosed cervical abnormality</li>
                  <li>Reduced sensitivity in ancillary out-of-the-vial testing such as HPV &amp; CT/NG</li>
                </ul>
                <p>
                  A missed or delayed diagnosis might allow for unnecessary disease persistence and/or progression between screening events for women. Obtaining a more complete sample with aid from the exCellerator can reduce the chance of an inadequate sample result and/or a missed diagnosis and opportunity for more timely treatment.
                </p>
              </Accordion>
              <Accordion title="What if there was a way for all clinicians and their assistants to achieve a more complete transfer of all samples collected?">
                <p>The FDA-cleared exCellerator addresses this issue in one simple step. Simply moving the cytobrush through the hole in the spatula to gently tease the sample out of the bristles can yield a more complete sample. The exCellerator is offered as an aid in achieving a more complete transfer of the sample from the cytobrush into the ThinPrep vial. One simple step to a more complete sample.</p>
                <p>The exCellerator is a complement to the ThinPrep (TP) Pap Test system, and studies show that it enables users to successfully achieve a more complete transfer of the collected sample into the ThinPrep vial.</p>
                <p>Having a more complete sample can significantly improve testing adequacy and can also provide more sufficient residual sample for ancillary tests that enable more comprehensive and accurate screening for your patient. Some potential benefits might include:</p>
                <ul>
                  <li>
                    Fewer Unsatisfactory or QNS Results:
                    <ul>
                      <li>Fewer repeat visits and tests</li>
                      <li>Less wasted time and expense for patients</li>
                      <li>More productive use of time and care for clinicians</li>
                      <li>More efficient use of medical insurance dollars</li>
                    </ul>
                  </li>
                  <br />
                  <li>
                    Earlier detection
                    <ul>
                      <li>More appropriate and timely treatment for patient</li>
                      <li>Enables clinicians to better care for patients</li>
                      <li>Reduces unnecessary downstream health issues for patients</li>
                      <li>Reduces unnecessary downstream costs for patients, clinicians, labs, and payors</li>
                    </ul>
                  </li>
                </ul>
              </Accordion>
          </div>
        </Layout>
      )
    }}
  />
)

export default ClinicalResults
